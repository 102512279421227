#infoPageContainer {
    padding: 10px;
}

#infoPageContainer h1 {
    margin: 0;
}

#infoPageContainer ul {
    list-style-type: none;
}

#infoPageContainer li {
    margin: 10px;
}

#infoPageContainer h3 {
    margin: 0;
}

#infoPageContainer p {
    margin: 0;
}