#reqTopBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #444444;
    margin: 2vh;
}

#twitchLogo {
    width: 5vh;
}

#onOffButton {
    width: 90%;
    height: 60px;
    margin: 2vh;
}

#onOffButtonText {
    font-size: xx-large;
    margin: 0;
}

#boostRequestWindow {
    position: fixed;
    background-color: #666666;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.popupButtonsContainer {
    display: flex;
    justify-content: space-around;
}

.requestsSection {
    text-align: center;
    padding: 1vh;
    margin: 0;
}

.requestsSection.light {
    background-color: #444444;
}

.requestsSection.dark {
    background-color: #222222;
}

.requestContentContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: baseline;
    padding: 1em;
}

.requestContentContainer.freeRequest {
    border-radius: 10px;
}

.requestGroupContainer {
    padding: 1vh;
    margin: 1vh;
    border-radius: 15px;
}

.requestGroupContainer:nth-child(odd) {
    background-color: #6e4444;
}

.requestGroupContainer:nth-child(even) {
    background-color: #3e3c52;
}

.requestGroupContainer.boosted {
    background-color: #73479c;
    margin-top: 20px;
    margin-bottom: 25px;
    box-shadow: 0px 0px 40px 0px #a671d8;
}

.requestGroupContainer.inactive {
    background-color: #4d4d4d;
}

.requestGroupContainer.completed {
    background-color: #3d3d3d;
}

.requestsContainer {
    position: relative;
    margin: 1vh;
}

.requestContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vh;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.267);
}

.requestButton {
    background-color: transparent;
    opacity: 0.5;
    padding: 0;
    text-align: center;
    margin: 1vh;
    box-shadow: none;
    height: 25px;
    width: 25px;
}

.requestButton.boost:disabled {
    background-color: transparent;
}

.requestButton.boost:disabled img {
    filter: grayscale(100%);
}

.requestButton:hover:enabled {
    transform: scale(110%);
    box-shadow: none;
    background-color: transparent;
    opacity: 1;
}

.requestButton:active:enabled {
    transform: scale(90%);
}

.requestButtonImage {
    width: 25px;
}

.requestButtonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    margin: 10px;
    margin-right: 0;
    border-radius: 10px;
    background-color: #00000011;
}

.beatmapInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.beatmapTextContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-around;
    background-color: #0000001f;
    padding: 10px;
    border-radius: 10px;
}

.beatmapDetailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.beatmapDetailsContainer span {
    margin-right: 15px;
    font-size: x-large;
}

.beatmapIconsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.difficultyRatingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: baseline;
}

.difficultyRatingContainer span {
    font-size: x-large;
    margin: 0;
}

.difficultyRatingContainer img {
    height: 30px;
    margin-right: 5px;
    transform: translateY(-9%);
}

.requestTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 1vh;
    text-align: left;
}

.requestLink {
    font-size: large;
}

.requestImage {
    margin: 7px;
}

.requestImage.mode {
    height: 30px;
}

.requestImage.status {
    height: 30px;
}

.requestText {
    font-size: medium;
}

.beatmapVersion {
    margin: 0;
}

.requestText.freeRequest {
    font-size: x-large;
    color: #ffc862;
}

.requestText.username {
    color: #FFF;
    text-decoration: none;
}

.requestText.username.inactive {
    text-decoration: line-through;
    color: #0000004f;
}

.requestText.usernameDivider {
    margin: 0 10px;
}

.requestText.timestamp {
    font-size: x-large;
    margin-right: 10px;
    color: #0000004f;
}

.osuDirectButton {
    position: relative;
    padding: 0;
    margin: 10px;
    opacity: 0.5;
    transition: all 150ms;
}

.osuDirectButton:hover {
    opacity: 1;
    margin: 15px;
}

.osuDirectButton img {
    width: 40px;
}

.redText {
    color: #f53333;
}

a {
    text-decoration: none;
}

a:link {
    color: #84acdf;
}

a:visited {
    color: #a671d8;
}