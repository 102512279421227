@font-face {
    font-family: "Lilian";
    src: local("Lilian"), url(./fonts/Lilian.ttf);
}

.centerPage {
    font-family: 'Lilian', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center;
    padding: 5vh;
}

@keyframes up-and-down {
    from {transform: translate(0px, 0px);}
    to {transform: translate(0px, -10px);}
}

.infoMessage {
    animation: up-and-down 2s ease 0ms infinite alternate both;
    text-align: center;
    color: white;
    font-size: 5vh;
}

.infoMessage.error {
    color: #a74040
}

#mainDiv {
    font-family: 'Lilian', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: white;
}

#topBar {
    display: flex;
    flex-direction: row;
}

#returnButton {
    position: fixed;
    left: 0vh;
    top: 0vh;
    padding: 0;
}

#returnButtonImage {
    height: 6vh;
}

#userSearchBarContainer {
    position: relative;
    margin: 10px;
}

#userSearchBarInput {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #202020;
    border-radius: 25px;
    transition: width 200ms;
}

#userSearchBarInput input {
    border: none;
    background-color: transparent;
    color: #FFF;
    padding: 10px;
    padding-left: 15px;
    margin: 0;
    font-size: large;
    transition: font-size 100ms, width 200ms;
    outline: none;
    width: 185px;
}

#userSearchBarContainer:focus-within input {
    font-size: xx-large;
    width: 50vw;
}

#userSearchBarContainer:focus-within span {
    height: 30px;
}

#userSearchBarContainer:focus-within #userSearchOutputContainer {
    visibility: visible;
}

#userSearchBarInput span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    margin-right: 0;
    height: 20px;
    transition: height 100ms;
}

#userSearchBarInput span img {
    height: 100%;
}

#userSearchBarInput .loadingSpinner {
    opacity: 0;
    width: 0;
}

#userSearchBarInput:focus-within .loadingSpinner {
    opacity: 1;
    width: 20px;
    margin-right: 20px;
}

@keyframes loadingRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loadingSpinner {
    width: 20px;
    height: 20px;
    border: solid 4px transparent;
    border-top-color: #646464;
    border-radius: 50%;
    animation: loadingRotate 1s linear 0ms infinite normal both;
}

#userSearchOutputContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: absolute;
    background-color: #202020;
    z-index: 1;
    width: 100%;
    top: 110%;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px;
    padding-left: 20px;
    visibility: hidden;
}

.userSearchResultContainer {
    margin: 5px;
}

#userpageHeaderContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#userInfoHeaderContainer {
    text-align: center;
}

#userInfoHeaderContainer p {
    margin: 0;
    margin-top: 10px;
}

#usernameText {
    font-size: xx-large;
    margin: 10px;
}

#userLevelText {
    font-size: x-large;
    margin: 10px;
}

.linebreak {
    background-color: white;
    margin: auto;
    height: 1px;
    width: 20vh;
}

#toxicityMeterContainer {
    margin-left: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.semicircleProgressBar {
    background: conic-gradient(at 50% 100%, rgb(199, 128, 0) 0deg, rgb(199, 128, 0) 19.8deg);
    height: 45px;
    width: 90px;
    border-top-left-radius: 90px;
    border-top-right-radius: 90px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.semicircleProgressBarInner {
    height: 80%;
    width: 80%;
    border-radius: inherit;
    background-color: #2b2b2b;
}

.semicircleProgressBarMainTick {
    position: absolute;
    clip: rect(0, 20px, 25px, 0);
    width: 5px;
    height: 45px;
    align-self: left;
    transform-origin: bottom;
    background-color: #646464;
}

.semicircleProgressBarTick {
    position: absolute;
    background-color: #3a3a3a;
    align-self: left;
    clip: rect(15px, 20px, 25px, 0);
    transform-origin: bottom;
    height: 45px;
    width: 5px;
}

#toxicityMeterLabel {
    margin: 0;
    margin-top: 15px;
}

#toxicityMeterLabel a:link {
    color: #1ecae0;
}

#toxicityMeterLabel a:visited {
    color: #1ecae0;
}

#dataContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    
}

#dataContainer a:link {
    color: #1ecae0;
}

#dataContainer a:visited {
    color: #1ecae0;
}

#statsContainers {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.statContainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: minmax(0, 100%);
    gap: 10px 10px;
    font-size: large;
    background-color: #3a3a3a;
    padding: 20px;
    margin: 1vh;
}

.statContainerHeading {
    display: contents;
    font-size: larger;
    margin: 0;
    font-weight: normal;
    grid-row: 1 / span 2;
}

.statTextContainer:hover .statDetailContainer {
    opacity: 1;
    visibility: visible;
}

.statDetailContainer {
    transition: opacity 100ms;
    visibility: collapse;
    background-color: #4d4d4d;
    opacity: 0;
    padding: 1vh;
    margin-top: 0.5vh;
    margin-left: -1vh;
    border-radius: 10px;
    position: absolute;
}

#currencyContainerUserpage {
    display: grid;
    grid-template-columns: auto auto;
    background-color: #3a3a3a;
    font-size: x-large;
    height: 100%;
    align-items: center;
    padding: 2vh;
    margin: 1vh;
}

.currencyImageContainer:hover > .currencyStatsTooltip {
    opacity: 100;
    visibility: visible;
}

.currencyStatsTooltip {
    opacity: 0;
    background-color: #646464;
    border-radius: 10px;
    padding: 0 2vh;
    display: grid;
    position: absolute;
    grid-template-columns: auto auto;
    gap: 0 1vh;
    font-size: medium;
    visibility: collapse;
    transition: opacity 100ms;
}

#achievementsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 500px;
    margin: 1vh;
}

.achievementContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    padding: 10px;
    margin: 1vh;
    width: 125px;
    height: 125px;
}

.achievementImage {
    position: absolute;
    width: 90px;
    height: 90px;
    z-index: 0;
}

.achievementTextBackground {
    position: absolute;
    align-self: stretch;
    height: 125px;
    width: 125px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.achievementText {
    font-size: large;
    height: fit-content;
    margin: 0;
    z-index: 2;
}

.achievementDetailContainer {
    position: absolute;
    visibility: hidden;
    transition: opacity 100ms;
    opacity: 0;
    height: fit-content;
    max-width: 125px;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
    z-index: 3;
}

.achievementContainer:hover .achievementDetailContainer {
    visibility: visible;
    opacity: 1;
}

.seenItemsContainer {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    background-color: #3a3a3a;
    margin: 1vh;
    padding: 1vh;
    padding-top: 30px;
    justify-content: flex-start;
    text-align: center;
}

.seenItemsSubContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.seenItemsSubtitle {
    font-size: x-large;
    margin: 0;
    margin-top: 20px;
}

.seenItemsAmount {
    margin: 0;
    margin-bottom: 10px;
}

.seenItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 10px;
    border-radius: 20px;
    padding: 10px;
    margin: 0.5vh;
    width: 110px;
    height: 110px;
    justify-content: space-around;
}

.seenItemHoverable {
    position: absolute;
    visibility: hidden;
    height: fit-content;
    padding: 40px 0px;
    width: 125px;
    border-radius: 10px;
}

.seenItemContainer:hover .seenItemHoverable {
    visibility: visible;
}

.seenItemImage {
    max-width: 70%;
    max-height: 70%;
    margin: 0;
}

.seenItemName {
    margin: 0;
}

.itemsCountContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.itemsCountHoverable {
    position: absolute;
    visibility: hidden;
    background-color: #3a3a3a;
    display: flex;
    flex-direction: column;
    justify-items: space-around;
    align-items: center;
    margin-top: 140px;
    border-radius: 10px;
    padding: 20px;
    border-width: 10px;
    border-color: black;
    border-style: solid;
}

.itemsCountContainer:hover .itemsCountHoverable {
    visibility: visible;
}

.itemsCountHoverableTitle {
    font-size: x-large;
    margin: 0;
}

.itemsCountHoverableSubText {
    margin: 0;
}