@font-face {
    font-family: "Lilian";
    src: local("Lilian"), url(./fonts/Lilian.ttf);
  }

#loginText {
    font-size: 8vh;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Lilian', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}