#leaderboardTableContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#leaderboardTitle {
    margin: 10px;
    margin-top: 20px;
    margin-bottom: 0;
}

#leaderboardTable {
    display: table;
    margin: 2vh;
    border-spacing: 0 3px;
    border-radius: 4px;
}

#leaderboardTable thead tr {
    background-color: transparent;
}

#leaderboardTable tbody {
    border-radius: 4px;
}

#leaderboardTable tbody tr {
    background-color: #353535;
    border-radius: 4px;
}

.leaderboardEntryContainer td {
    text-align: center;
    padding: 5px;
}

.leaderboardEntryContainer .userLinkTableData {
    text-align: left;
    width: 200px;
}