@use "sass:math";

:root {
  --legendary-orange: #fca503;
  --epic-purple: #8f23c2;
  --rare-blue: #3773ac;
  --uncommon-green: #4CAF50;
  --common-gray: #b6b6b6;

  --main-background: #2b2b2b;

  --cubic-ease: cubic-bezier(0, 0.55, 0.45, 1);
}

.App {
  text-align: left;
  font-family: 'Lilian', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  width: 100%;
  height: 100%;
}

#xpBar {
  box-sizing: border-box;
  width: 100%;
  padding-left: 1vh;
  padding-right: 1vh;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
}

.xpBarLevelIndicator {
  font-size: xx-large;
  margin: 1vh;
  line-height: 100%;
}

#xpBarTooltip {
  grid-column: 1;
  grid-row: 1;
  transition: opacity 100ms;
  opacity: 0;
  margin: auto;
  font-size: medium;
  text-align: center;
}

#xpBarBackground:hover #xpBarTooltip {
  opacity: 1;
}

#xpBarBackground {
  display: grid;
  margin: 1vh;
  width: 100%;
  height: 2vh;
  background-color: #353535;
  border-radius: 10px;
}

#newXpIndicator {
  grid-column: 1;
  grid-row: 1;
  text-align: center;
  font-size: x-large;
  animation: riseOut 1s linear 0ms 1 normal both;
}

#xpBarForeground {
  grid-column: 1;
  grid-row: 1;
  height: 2vh;
  border-radius: 10px;
  transition: width 500ms;
}

#topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.topBarButtonsContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.topBarButtonsContainer.vertical {
  flex-direction: column;
}

.topBarButtonsContainer.horizontal {
  flex-direction: row;
}

@keyframes floatOut {
  0% { opacity: 100%; transform: translate(0px, 0px);}
  25% { transform: translate(2px, -5px);}
  50% { transform: translate(0px, -10px);}
  75% { transform: translate(-2px, -15px);}
  100% { opacity: 0%; transform: translate(0px, -20px);}
}

.popupWindowContainer {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #353535;
  box-shadow: 7px 7px 0px rgba(0, 0, 0, 0.5);
  padding: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupWindowContainer h1 {
  margin: 0;
}

.popupWindowButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.popupWindowButton.cancel {
  background-color: #a74040;
}

.youtubeInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupWindowNote {
  margin: 0;
}

.popupInput {
  resize: none;
  width: 50%;
  border-radius: 5px;
  font-size: large;
  width: 50vw;
  margin: 2vh;
  height: 3vh;
  line-height: 3vh;
}

#popupsContainerBackground {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.popupContainer {
  padding: 10px;
  background-color: #3a3a3a;
}

.sellPriceContainer {
  padding: 5px;
  border-style: solid;
  border-width: 5px;
}

.itemDisplay {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  padding: 10px;
}

.itemDisplay.sell {
  width: 100px;
  height: 100px;
  margin: 5px;
  margin-bottom: 20px;
}

.itemDisplay.sell .sellPriceContainer {
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
}

.confirmSellPopupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmSellPopupContainer h1 {
  margin: 5px;
}

.popupButtonsContainer {
  margin: 10px;
}

.itemRollPopupContainer {
  position: fixed;
  z-index: 1;
  width: 90%;
  max-width: 800px;
  height: 25vw;
  max-height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

#backpackOpenerContainer {
  display: flex;
  width: 100px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-left: 10px;
  margin-bottom: 10px;
}

#backpackOpenerContainer span {
  position: absolute;
  font-size: medium;
  color: #d7ffe7;
  translate: 0px 5px;
}

#backpackOpenerContainer span.redText {
  color: #ff7373;
}

#backpackOpenerContainer svg {
  position: relative;
}

#backpackIcon {
  fill: #707070;
  height: 100px;
}

#backpackIcon:hover {
  fill: #FFF;
}

#backpackWindow {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  background-color: rgb(27, 27, 27);
  padding: 10px;
}

#backpackFunctionButtonsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  margin-top: 1vh;
}

.backpackFunctionButtonContainer {
  margin: 0 10px;
}

.backpackFunctionSvg {
  height: 60px;
  transition: fill 100ms;
}

.backpackFunctionSvg:hover {
  fill: #FFF;
}

.backpackFunctionSvg:active {
  fill: #4CAF50;
}

.backpackSection {
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 1vh;
  max-width: 100%;
}

.backpackSectionImage {
  height: 80px;
  width: 80px;
  margin-right: 10px;
}

.divider {
  background-color: #FFF;
  width: 1px;
  margin-left: 10px;
  margin-right: 10px;
}

.backpackButtonsContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  padding: 0;
}

.backpackButton {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100px;
  margin-left: 0.5vh;
  margin-right: 0.5vh;
  margin-bottom: 2vh;
  padding: 15px;
}

.backpackButton:hover .hoverableButtonPrice {
  opacity: 1;
}

.backpackButtonContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.buttonImage {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.buttonText {
  font-size: medium;
  word-wrap: break-word;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}

.backpackButtonTooltip {
  opacity: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: #000000b9;
  padding: 5px 10px;
  top: 0%;
  translate: 0 -50%;
  border: 4px solid #000;
  transition: opacity 250ms cubic-bezier(0.215, 0.610, 0.355, 1);
}

.backpackButton:hover .backpackButtonTooltip {
  opacity: 1;
}

.popupWindowTitle {
  margin: 0;
}

.backpackRollButtonImage {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.sellPopupItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 150px;
  height: 150px;
  margin: 10px;
  margin-bottom: 25px;
}

.sellPopupItemImage {
  width: 80%;
}

.sellPopupItemPrice {
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
  border-style: solid;
  border-width: 3px;
  padding: 5px;
}

#twitchViewTopContainer {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  justify-content: center;
  flex-grow: 1;
  margin: 10px;
}

#twitchViewContainer {
  flex-grow: 1;
}

#twitchView {
  display: flex;
  align-items: center;
}

#twitchViewTopContainer button {
  width: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  margin: 0;
  background-color: #0000005b;
}

#twitchViewTopContainer button:enabled:hover {
  transform: none;
  box-shadow: none;
  background-color: #000000f8;
}

#twitchViewTopContainer button span {
  font-size: xx-large;
  margin: 0;
}

.twitchViewButtonContainer button {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0 10px;
}

.twitchViewButtonContainer button:enabled:hover {
  box-shadow: none;
}

.twitchViewButtonContainer button:enabled:active {
  box-shadow: none;
}

.twitchViewButtonContainer button img {
  width: 100px;
}

#mainContentContainer {
  display: flex;
  flex-direction: column;
}

#currencyContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  max-width: 100%;
  background-color: #3a3a3a;
  margin: 1vh;
  padding: 1vh;
}

.currencySubContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;
  margin: 0.5vh;
  height: 40px;
  background-color: #464646;
}

.currencyImage {
  width: 30px;
  margin: 10px;
}

@keyframes toWhite {
  to {color: #FFF}
}

@keyframes riseOut {
  from {opacity: 1; transform: translateY(0px);}
  to {opacity: 0; transform: translateY(-10px);}
}

.currencyCounter {
  color: white;
  font-size: x-large;
  padding: 2px;
  animation: toWhite 1s ease 1s 1 normal both;
}

.currencyDelta {
  position: relative;
  animation: riseOut 1s linear 0ms 1 normal both;
  font-size: 3vh;
  left: 1vh;
  top: -2vh;
  width: 0;
  align-self: right;
}

.currencyContainer.bananas {
  background-color: #727272;
  padding: 1vh;
}

.currencyButtonContainer {
  display: flex;
  align-self: stretch;
  margin: 0 1vh;
}

.currencyButtonContainer button {
  margin: 0;
  padding: 5px;
  background-color: transparent;
  box-shadow: none;
  width: 120px;
  height: 100%;
} 

.currencyButtonContainer button:hover {
  transform: scale(1.05);
  box-shadow: none;
}

.currencyButtonContainer button:active {
  transform: scale(0.9);
}

.currencyButtonContainer button img {
  width: 100%;
  image-rendering: pixelated;
}

.currencyButtonContainer span {
  font-size: 3em;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.37);
  position: absolute;
}

.currencyButtonContainer.goldenBanana span {
  color: #ffea74;
}

.currencyButtonContainer.subPoint span {
  color: #afd0f5;
}

#goldenBananaUpgradesWindow {
  width: 100%;
  padding: 1vh;
  background-color: #e18966;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-image: url("./media/beach-bg.png");
  image-rendering: pixelated;
  background-size: 600px;
  box-sizing: border-box;
  background-position: 0% -7vw;
  background-repeat: repeat-x;
}

.goldenBananaUpgradeButtonContainer {
  margin: 1vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.goldenBananaUpgradeButtonContainer:hover .goldenBananaUpgradeButtonHoverable {
  visibility: visible;
  opacity: 1;
}

.goldenBananaUpgradeButtonContainer button {
  padding: 0;
  background-color: transparent;
  z-index: 1;
  position: relative;
  box-shadow: none;
  width: 150px;
  height: 150px;
}

.goldenBananaUpgradeButtonContainer button:enabled:hover {
  box-shadow: none;
  transform: none;
}

.goldenBananaUpgradeButtonContainer button:enabled:active {
  box-shadow: none;
  transform: scale(95%, 95%);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.goldenBananaUpgradeButtonContents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes growIn {
  from {transform: scale(0%, 0%);}
  to { transform: scale(100%, 100%); }
}

.goldenBananaUpgradeButtonImage {
  image-rendering: pixelated;
  transform-origin: 35% 100%;
  width: 60%;
  transition: transform 500ms;
  translate: 5% 0;
}

$goldenBananaUpgradeButtonCount: 6;
@for $i from 0 to $goldenBananaUpgradeButtonCount {
  .goldenBananaUpgradeButtonContainer:nth-child(#{$i + 1}) .growIn {
    animation: growIn 200ms ease-out #{$i * 50}ms 1 normal backwards;
  }
}

@keyframes wave {
  from { transform: rotate(-7deg); }
  to { transform: rotate(5deg); }
}

.goldenBananaUpgradeButtonContents:hover img {
  animation: wave 1s ease-in-out 0ms infinite alternate forwards;
}

.goldenBananaUpgradeButtonContents:hover .multiplierAmountDisplay h1 {
  opacity: 0;
  visibility: hidden;
}

.goldenBananaUpgradeButtonContents:hover .nextMultiplierAmountDisplay {
  opacity: 1;
  visibility: visible;
}

.goldenBananaUpgradeName {
  margin: 0px;
  margin-top: 10px;
  font-size: medium;
  text-shadow: 1px 2px #11111180;
}

.goldenBananaUpgradeButtonContents .multiplierAmountDisplay {
  position: absolute;
  top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goldenBananaUpgradeButtonContents .multiplierAmountDisplay h1 {
  font-size: x-large;
  text-shadow: 2px  2px #11111180;
  margin: 0;
  visibility: visible;
  opacity: 1;
}

.goldenBananaUpgradeButtonContents .multiplierAmountDisplay h2 {
  font-weight: 100;
  font-size: medium;
  text-shadow: 2px  2px #11111180;
  margin: 0;
}

.goldenBananaUpgradeButtonContents .nextMultiplierAmountDisplay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -1%;
}

.goldenBananaUpgradeButtonContents .nextMultiplierAmountDisplay h1 {
  color: #ffa41c;
  font-size: x-large;
  text-shadow: 2px  2px #11111180;
}

.goldenBananaUpgradeButtonHoverable {
  visibility: hidden;
  position: absolute;
  margin: 0;
  margin-top: 5px;
  opacity: 0;
  z-index: 2;
  transition: opacity 100ms;
  border-radius: 10px;
  width: 160px;
  top: 100%;
  background-color: #b97d23;
  border: solid 5px #f7c94a;
  text-align: center;
  padding: 10px;
}

.goldenBananaUpgradeButtonHoverable p {
  margin: 0;
}

#goldenBananaRespecButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#goldenBananaRespecButtonContainer button {
  background-color: transparent;
  box-shadow: none;
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms;
  width: 150px;
  height: 150px;
}

#goldenBananaRespecButtonContainer button:enabled:hover {
  transform: scale(105%, 105%);
  box-shadow: none;
}

#goldenBananaRespecButtonContainer button:enabled:active {
  scale: 90%;
}

#goldenBananaRespecButtonContainer button h2 {
  position: absolute;
  color: #1d9100;
  font-weight: 100;
  font-size: 1em;
}

.respecTextGlow {
  background-color: #fff099;
  position: absolute;
  height: 1px;
  width: 1px;
  box-shadow: 0px 0px 15px 15px #fff099;
}

#respecGoldenBanana {
  image-rendering: pixelated;
  width: 75%;
  position: relative;
}

#respecArrowsContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes arrowSpin {
  from {rotate: 0deg;}
  to {rotate: 360deg;}
}

#respecArrowsContainer .respecArrow {
  position: absolute;
  image-rendering: pixelated;
  transform-origin: 50%;
  transition: rotate 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#goldenBananaRespecButtonContainer button:hover .respecArrow {
  transition: rotate 10s cubic-bezier(0.165, 0.84, 0.44, 1);
  rotate: 1080deg;
  //animation: arrowSpin 4s linear 0ms infinite normal forwards;
}

$arrowsCount: 3;
$arrowRadius: 35;
@for $i from 0 through $arrowsCount {
  #respecArrowsContainer .respecArrow:nth-child(#{$i + 1}) {
    $angle: $i * math.div(2 * math.$pi, 3);
    transform: translate(#{math.cos($angle) * $arrowRadius}px, #{math.sin($angle) * $arrowRadius}px) rotate(#{$angle - 3.45}rad);
  }
}

.goldenBananaRespecPopupTextContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin: 10px;
  padding: 10px;
  border: 2px solid #FFF;
  background-color: #575757;
}

.goldenBananaRespecPopupTextContainer span {
  margin: 10px 10px;
}

.goldenBananaRespecPopupTextContainer div {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.goldenBananaRespecPopupTextContainer div h1 {
  margin: 0;
}

.goldenBananaRespecPopupTextContainer div h2 {
  margin: 0;
  font-weight: 100;
  color: #FFF;
  font-size: medium;
}

#subPointUpgradesWindow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #111111;
  box-sizing: border-box;
  padding: 5vh;
}

@keyframes oscillateScale {
  from { transform: scale(50%, 50%) translate(-50%, -50%); opacity: 0.1; }
  to {transform: scale(100%, 100%) translate(50%, 50%); opacity: 0.3; }
}

.backgroundStar {
  position: absolute;
  background-color: #FFF;
  border-radius: 50%;
  animation: oscillateScale 3s ease 0ms infinite alternate both;
}

$starsCount: 50;
@for $i from 0 to $starsCount {
  .backgroundStar:nth-child(#{$i + 1}) {
    top: random($limit: 100) / 100 * 100%;
    left: random($limit: 100) / 100 * 100%;
    $size: random($limit: 10);
    height: #{$size}px;
    width: #{$size}px;
    animation-delay: random($limit: 3000) * 1ms;
    animation-duration: random($limit: 4) * 2s;
  }
}

#subPointUpgradeButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 0;
}

#subPointUpgradeButtonsContainer:hover .subUpgradeButton {
  opacity: 0.3;
  transform: translateY(20%);
}

#subPointUpgradeButtonsContainer:hover .subUpgradeButton:hover {
  opacity: 1;
  transform: translateY(0%) scale(120%, 120%);
}

@keyframes riseIn {
  from { transform: translateY(100%); opacity: 0; }
  to { transform: translateY(0%); opacity: 1; }
}

.subUpgradeButton {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  margin: 0;
  border-radius: 100px;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  animation: riseIn 500ms cubic-bezier(0, 0.55, 0.45, 1) 10ms 1 normal backwards;
  transition: all 500ms;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}

$subUpgradeButtonCount: 5;
@for $i from 0 to $subUpgradeButtonCount {
  .subUpgradeButton:nth-child(#{$i + 1}) {
    animation-delay: #{$i * 30}ms;
  }
}

.subUpgradeButton:hover:enabled {
  transform: scale(130%, 130%);
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: none;
}

.subUpgradeButton img {
  width: 60%;
  height: 60%;
}

.subUpgradeButton span {
  margin-top: 20px;
  text-align: center;
}

.subUpgradeButton .amountText {
  position: absolute;
  color: #ffffff71;
  top: 0%;
  left: 10%;
  font-size: x-large;
}

.tooltip {
  position: absolute;
  visibility: hidden;
}

.subUpgradeButton .tooltip {
  top: 105%;
  font-size: smaller;
  background-color: #2b2b2b;
  border-radius: 20px;
  padding: 1vh;
}

.subUpgradeButton .nextMultiplierText {
  position: absolute;
  font-size: large;
  opacity: 0;
  color: var(--uncommon-green);
  transition: all 300ms;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}

.subUpgradeButton .upArrowSvg {
  fill: #000;
  opacity: 0;
  position: absolute;
  width: 50%;
  transition: all 300ms;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}

.subUpgradeButton .upArrowSvg defs linearGradient stop {
  offset: 50%;
}

.subUpgradeButton .multiplierText {
  position: absolute;
  top: -15%;
  font-size: large;
  color: var(--legendary-orange);
  transition: all 300ms;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.subUpgradeButton:hover:active {
  box-shadow: none;
  animation: shake 1600ms linear 0ms infinite normal forwards;
}

.subUpgradeButton:enabled:active {
  box-shadow: none;
}

.subUpgradeButton:hover .tooltip {
  visibility: visible;
}

.subUpgradeButton:hover .multiplierText {
  top: 80%;
  transform: scale(120%, 120%);
  text-shadow: 1px 1px black;
}

.subUpgradeButton:hover .upArrowSvg {
  opacity: 0.8;
}

.subUpgradeButton:hover .nextMultiplierText {
  opacity: 1;
  top: 0%;
  transform: scale(120%, 120%);
  text-shadow: 1px 1px black;
}

.subUpgradeButton:hover img {
  opacity: 1;
  transform: scale(90%, 90%) translateY(10%);
}

.currencyRewardsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.currencyRewardsContainer.common {
  background-color: #d1d1d1;
}

.currencyRewardsContainer.uncommon {
  background-color: #2c6627;
}

.currencyRewardsContainer.rare {
  background-color: #1d2a74;
}

.currencyRewardsContainer.epic {
  background-color: #611b81;
}

.rewardsContainerHeadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 200ms;
}

.currencyAmountText {
  font-size: medium;
  margin: 10px;
  font-size: x-large;
  animation: toWhite 1s ease-out 0ms 1 normal both;
}

.rewardsContainerHeadingContainer:hover .rewardsContainerHeadingTooltip {
  visibility: visible;
  position: relative;
  width: max-content;
  max-width: 750px;
  opacity: 1;
  left: 0;
}

.rewardsContainerHeadingTooltip {
  position: absolute;
  display: flex;
  align-items: center;
  opacity: 0;
  left: 100%;
  visibility: hidden;
  width: fit-content;
  background-color: #0000005b;
  padding-right: 2rem;
  max-width: 0;
  height: 45px;
  transition: max-width 250ms cubic-bezier(0.215, 0.610, 0.355, 1), opacity 250ms cubic-bezier(0.215, 0.610, 0.355, 1);
  overflow: hidden;
}

.rewardsContainerHeadingTooltip h1 {
  box-sizing: content-box;
  font-size: medium;
  white-space: nowrap;
  text-overflow: clip;
}

.rewardsContainerHeadingCurrencyAmountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  height: 45px;
  background-color: #0000005b;
}

.rewardsContainerHeadingCurrencyAmountContainer img {
  width: 30px;
}

.rewardButtonsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.rewardMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2vh;
}

.rewardMenuButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 125px;
  height: 125px;
  padding: 15px;
  margin: 1vh;
}

.rewardMenuButton:hover {
  filter: brightness(110%);
}

.rewardMenuButton:active {
  filter: brightness(90%);
}

.hoverableButtonPrice {
  transition: opacity 100ms;
  opacity: 0.8;
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
  margin: auto;
  width: fit-content;
  padding: 2px;
  padding-right: 5px;
  border-style: solid;
  border-width: 2px;
}

.rewardMenuButton:hover .hoverableButtonPrice {
  opacity: 1;
}

.rewardMenuButton.video {
  width: auto;
  height: auto;
}

.rewardMenuButton.text {
  width: auto;
  height: auto;
}

.rewardMenuButton.red {
  background-color: #a74040;
}

.rewardMenuButtonImage {
  max-width: 100%;
  max-height: 100%;
}

.rewardMenuButtonText {
  max-width: 100%;
}

.chargeBarContainer {
  display: flex;
  position: absolute;
  height: 10px;
  width: 80%;
}

.chargeBarBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000005b;
}

.chargeBarForeground {
  height: 100%;
  background-color: #ffffff;
  position: absolute;
  opacity: 0.3;
  transition: all 0s linear;
  width: 0%;
}

.chargeBarForeground.charging {
  opacity: 1;
  width: 100%;
  transition: all 2s linear;
}

@keyframes spin {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.rewardButtonImage {
  max-width: 60%;
  max-height: 60%;
  width: 100%;
  margin: 2%;
}

.rewardButtonLevelContainer {
  position: absolute;
  text-align: center;
}

.rewardButtonLevel {
  position: absolute;
  font-size: xx-large;
  color: #2b2b2b;
}

.rewardPriceDisplayContainer {
  position: absolute;
  margin: 5px;
  text-align: left;
  height: 0;
}

.rewardPriceImage {
  height: 20px;
  margin-right: 7px;
}

.rewardPriceText {
  font-size: large;
}

.rewardButtonText {
  color: white;
  height: 0;
  font-size: medium;
  margin: 0.75vh;
}

.rewardButtonContainer {
  text-align: center;
  margin: 1vh;
}

.rewardButtonContainer:hover > .rewardMenu {
  opacity: 1;
}

.rewardButtonContentContainer {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rewardButton {
  padding: 0.5%;
  width: 150px;
  height: 150px;
  text-align: center;
}

.rewardButton.common {
  background-color: #b6b6b6;
}

.rewardButton.uncommon {
  background-color: #4CAF50;
}

.rewardButton.rare {
  background-color: #3773ac;
}

.commandButtonsDiv {
  text-align: center;
}

@keyframes subtleRotateAndScale {
  0% {transform: rotate(0deg) scale(1);}
  25% {transform: rotate(5deg) scale(1.03);}
  50% {transform: rotate(0deg) scale(1);}
  75% {transform: rotate(-5deg) scale(1.03);}
  100% {transform: rotate(0deg) scale(1);}
}

@keyframes RotateAndScale {
  0% {transform: rotate(0deg) scale(0.9);}
  25% {transform: rotate(20deg) scale(0.8);}
  50% {transform: rotate(0deg) scale(0.9);}
  75% {transform: rotate(-20deg) scale(0.8);}
  100% {transform: rotate(0deg) scale(0.9);}
}

.bananaButton:hover:enabled {
  transition: 250ms ease-in-out all;
  animation: subtleRotateAndScale 2s ease 0ms infinite normal none;
  background-color: transparent;
  box-shadow: none;
}

.bananaButton:active:enabled {
  animation: RotateAndScale 1s linear 0ms infinite normal none;
  transition: 100ms all;
}

.twitchLoginButton {
  background-color: #6441a5;
  padding: 3vh 3vh;
}

#navButtonsContainer {
  margin: 1vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  justify-content: center;
}

#navButtonsContainer button {
  width: 100%;
  padding: 15px 15px;
}

#profileButton {
  flex: 1;
  background-color: #6441a5;
}

#requestsButton {
  flex: 1;
}

.twitchLogo {
  height: 5.5vh;
  transform: translateY(20);
}

.twitchLoginButton:hover:enabled {
  background-color: #422677;
}

.commandRecievedCheckmark {
  margin-left: 10px;
  margin-right: -10px;
  width: 10px;
}

.centerContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@keyframes oscillate-opacity {
  from {opacity: 1;}
  to {opacity: 0.25;}
}

@keyframes up-and-down {
  from {transform: translate(0px, 0px);}
  to {transform: translate(0px, -10px);}
}

#disconnectedText {
  animation: oscillate-opacity 2s ease 0ms infinite alternate both;
  font-size: 10vh;
  color: #a74040;
  font-family: 'Lilian', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#connectingText {
  animation: oscillate-opacity 2s ease 0ms infinite alternate both;
  font-size: 10vh;
  color: #b8b8b8;
}

#tryRefreshingText {
  animation: up-and-down 2s ease 0ms infinite alternate both;
  font-size: 5vh;
  color: white;
  font-family: 'Lilian', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
