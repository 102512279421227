@font-face {
    font-family: "Lilian";
    src: local("Lilian"), url(./fonts/Lilian.ttf);
}

body {
    font-weight: normal;
    font-family: 'Lilian', 'Segoe UI', sans-serif;
}

input {
    font-family: 'Lilian', 'Segoe UI', sans-serif;
    font-weight: normal;
}

tr {
    font-family: 'Lilian', 'Segoe UI', sans-serif;
    font-weight: normal;
    color: white;
}

h1 {
    font-weight: 100;
    font-family: 'Lilian', 'Segoe UI', sans-serif;
    color: white;
}

h2 {
    font-weight: 100;
    font-family: 'Lilian', 'Segoe UI', sans-serif;
    color: white;
}

h3 {
    font-size: x-large;
    font-weight: 100;
    font-family: 'Lilian', 'Segoe UI', sans-serif;
    color: white;
}

p {
    font-weight: 100;
    font-family: 'Lilian', 'Segoe UI', sans-serif;
    color: white;
}

a {
    font-family: 'Lilian', 'Segoe UI', sans-serif;
}

span {
    color: white;
    font-family: 'Lilian', 'Segoe UI', sans-serif;
}

ul {
    color: white;
    font-family: 'Lilian', 'Segoe UI', sans-serif;
}

li {
    color: white;
    font-family: 'Lilian', 'Segoe UI', sans-serif;
}

button {
    background-color: #4CAF50; /* Green */
    margin: 5px;
    box-shadow: 5px 5px black;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: 'Lilian', 'Segoe UI';
    cursor: pointer;
    transition-duration: 150ms;
    touch-action: manipulation;
}
  
button:hover:enabled {
    filter: brightness(110%);
    box-shadow: 7px 7px black;
    transform: translate(-2px, -2px);
    transition-duration: 50ms;
}

button:active:enabled {
    filter: brightness(90%);
    box-shadow: 4px 4px black;
    transform: translate(1px, 1px);
}

button:disabled {
    opacity: 0.6;
    background-color: gray;
    cursor: default;
}

textarea {
    font-family: 'Lilian', 'Segoe UI', sans-serif;
    background-color: #e9e9e9;
}

textarea:focus {
    background-color: white;
}