#returnButton {
    position: fixed;
    text-align: center;
    z-index: 1;
    margin: 2vh;
    width: 50px;
    height: 50px;
    padding: 0;
}

#returnButton img {
    height: 50%;
}